import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {

    userInfo = this._authService.user;

    constructor(private _authService:AuthService,
        private _router: Router
        ) { }

    profileMenuList = [
        // {
        //     path: '',
        //     icon: 'feather icon-user',
        //     item: 'Profile',
        //     logout:false,
        // },
        // {
        //     path: '',
        //     icon: 'feather icon-settings',
        //     item: 'Settings',
        //     logout:false,
        // },
        // {
        //     path: '',
        //     icon: 'feather icon-life-buoy',
        //     item: 'Support',
        //     logout:false,
        // },
        {
            path: '',
            icon: 'feather icon-power',
            item: 'Sign Out',
            logout:true,
        }
    ]

    ngOnInit(): void { 
      console.log(this._authService.user);
    }

    signOut(){
        this._authService.signOut()
        this._router.navigate([`login`]);

    }
}
