import { nav } from './section/nav'

export const en_US = {
    ...nav,
    "Dashboard":"Dashboard",
    "Manufacturers ":"Manufacturers",
    "Home":"Home",
    "Notifications":"Notifications",
    "Mark all as read":"Mark all as read",
    "View all":"View all",
    "Theme Config":"Theme Config",
    "Side nav color":"Side nav color",
    "Light":"Light ",
    "Dark":"Dark",
    "Layout type":"Layout type",
    "Vertical":"Vertical",
    "Horizontal":"Horizontal",
    "Side nav collapse":"Side nav collapse",
    "Header nav color":"Header nav color",
    "Profile":"Profile",
    "Settings":"Settings",
    "Support":"Support",
    "Sign Out":"Sign Out",
    "Last Week":"Last Week",
    "Last Month":"Last Month",
    "Last 6 Months":"Last 6 Months",
    "Last Year":"Last Year",
    "Netto":"Netto",
    "Brutto":"Brutto",
    "Top Sales":"Top Sales",
    "Top 10 Products":"Top 10 Products",
    "Top Marketplaces":"Top Marketplaces",
    "Copyright":"Copyright",
    "All rights reserved":"All rights reserved",
    "Terms & Conditions":"Terms & Conditions",
    "Privacy & Policy":"Privacy & Policy",
    "January ":"January",
    "February":"February",
    "March":"March",
    "April":"April",
    "May":"May",
    "June":"June",
    "July":"July",
    "August":"August",
    "September":"September",
    "October":"October",
    "November":"November",
    "December":"December",
    "Download":"Download",
    "Sign In":"Sign In",
    "Sign in your account to continue":"Sign in your account to continue",
    "Username":"Username",
    "Password":"Password",
    "Forget Password":"Forget Password",
    "Don’t have an account yet?":"Don’t have an account yet?",
    "Sign Up":"Sign Up",
    "Sign Up to get free reward":"Sign Up to get free reward",
    "First name":"First name",
    "Last name":"Last name",
    "Email":"Email",
    "Confirm Password":"Confirm Password"

};
