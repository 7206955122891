export const nav = {
  NAV: {
    DASHBOARD: 'Dashboard',
    DASHBOARD_DEFAULT: 'Défaut',
    APPS: 'Applications',
    APPS_MAIL: 'Courrier',
    APPS_CHAT: 'Bavarder',
    APPS_CALENDAR: 'Calendrier',
    COMPONENTS: 'Composants',
    COMPONENTS_UIELEMENTS: 'Éléments de UI',
    COMPONENTS_UIELEMENTS_ACCORDION: 'Accordéon',
    COMPONENTS_UIELEMENTS_ALERT: 'Alerte',
    COMPONENTS_UIELEMENTS_BUTTONS: 'Boutons',
    COMPONENTS_UIELEMENTS_CAROUSEL: 'Carrousel',
    COMPONENTS_UIELEMENTS_COLLAPSE: 'Effondrer',
    COMPONENTS_UIELEMENTS_DATEPICKER: 'Sélecteur de date',
    COMPONENTS_UIELEMENTS_DROPDOWN: 'Menu déroulant',
    COMPONENTS_UIELEMENTS_MODAL: 'Modal',
    COMPONENTS_UIELEMENTS_PAGINATION: 'Pagination',
    COMPONENTS_UIELEMENTS_POPOVER: 'Popover',
    COMPONENTS_UIELEMENTS_PROGRESSBAR: 'Barre de progression',
    COMPONENTS_UIELEMENTS_RATING: 'Évaluation',
    COMPONENTS_UIELEMENTS_SORTABLE: 'Triable',
    COMPONENTS_UIELEMENTS_TABS: 'Onglets',
    COMPONENTS_UIELEMENTS_TIMEPICKER: 'Timepicker',
    COMPONENTS_UIELEMENTS_TOOLTIP: 'Info-bulle',
    COMPONENTS_UIELEMENTS_TYPEHEAD: 'Typehead',
    COMPONENTS_FORMELEMENTS: 'Éléments de formulaire',
    COMPONENTS_FORMELEMENTS_INPUT: 'Contribution',
    COMPONENTS_FORMELEMENTS_RADIO: 'Radio',
    COMPONENTS_FORMELEMENTS_CHECKBOX: 'Case à cocher',
    COMPONENTS_FORMELEMENTS_SELECT: 'Sélectionner',
    COMPONENTS_FORMELEMENTS_FORM: 'Forme',
    COMPONENTS_FORMELEMENTS_SWITCH: 'Commutateur',
    COMPONENTS_FORMELEMENTS_UPLOAD: 'Télécharger',
    COMPONENTS_TABLES: 'les tables',
    COMPONENTS_TABLES_TABLE: 'Table',
    COMPONENTS_TABLES_DATATABLE: 'Tableau de données',
    COMPONENTS_ICONS: 'Icônes',
    COMPONENTS_ICONS_FEATHER: 'Feather',
    COMPONENTS_ICONS_LINE_AWESOME: 'Line Awesome',
    COMPONENTS_MAPS: 'Plans',
    COMPONENTS_MAPS_AMMAP: 'AmMap',
    COMPONENTS_MAPS_GOOGLE_MAP: 'Google Map',
    CHART: 'Graphique',
    PAGES: 'Des pages',
    PAGES_UTILITY: 'Utilitaire',
    PAGES_UTILITY_PROFILE: 'Profil',
    PAGES_UTILITY_INVOICE: 'Facture d achat',
    PAGES_UTILITY_FAQ: 'FAQ',
    PAGES_UTILITY_PRICING: 'Tarification',
    PAGES_UTILITY_USERLIST: 'liste d utilisateur',
    PAGES_AUTH: 'Auth',
    PAGES_AUTH_LOGIN: "S'identifier",
    PAGES_AUTH_LOGIN_V2: 'Connexion v2',
    PAGES_AUTH_LOGIN_V3: 'Connexion v3',
    PAGES_AUTH_REGISTER: "S'inscrire",
    PAGES_AUTH_REGISTER_V2: "S'inscrire v2",
    PAGES_AUTH_REGISTER_V3: "S'inscrire v3",
    PAGES_ERRORS: 'Errors',
    PAGES_ERRORS_ERROR1: 'Erreur 1',
    PAGES_ERRORS_ERROR2: 'Erreur 2',
    OTHERS: 'Others',
    OTHERS_DOCUMENTATION: 'Documentation',
    OTHERS_CHANGELOG: 'Changelog',
  },
};