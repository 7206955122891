import { nav } from './section/nav'

export const fr_FR = {
    ...nav,
    "Dashboard":"Dashboard",
     "Manufacturers":"Hersteller",
     "Home":"Hersteller",
     "Notifications":"Benachrichtigungen",
     "Mark all as read":"Alle als gelesen markieren",
     "View all":"Alle anzeigen",
     "Theme Config":"Theme Config",
     "Side nav color":"Navigationsleistenfarbe",
     "Light":"Hell",
     "Dark":"Dunkel",
     "Layout type":"Layout-Typ",
     "Vertical":"Vertikal",
     "Horizontal":"Horizontal",
    "Side nav collapse": "Navigationsleiste reduzieren",
    "Header nav color": "Kopfzeilenfarbe",
    "Profile": "Profil",
    "Settings": "Einstellungen",
    "Support": "Support",
    "Sign Out": "Abmelden",
    "Last Week": "Letzte Woche",
    "Last Month":"Letzter Monat",
    "Last 6 Months": "Letzten 6 Monate",
    "Last Year": "Letztes Jahr",
    "Netto":"Netto",
    "Brutto":"Brutto",
    "Top Sales":"Top Sales",
    "Top 10 Products":"Top 10 Produkte",
    "Top Marketplaces":"Top Marktplätze",
    "Copyright":"Copyright",
    "All rights reserved":"Alle Rechte vorbehalten",
    "Terms & Conditions":"Allgemeine Geschäftsbedingungen",
    "Privacy & Policy":"Datenschutzrichtlinie",
    "January":"Januar",
    "February":"Februar",
    "March":"Marsch",
    "April":"April",
    "May":"Mai",
    "June":"Juni",
    "July":"Juli",
    "September":"September",
    "October":"Oktober",
    "November":"November",
    "December":"Dezember",
    "Download":"Herunterladen",
    "Sign In":"Einloggen",
    "Sign in your account to continue":"Melde dich an um fortzufahren",
    "Username":"Benutzername",
    "Password":"Password",
    "Forget Password":"Passwort vergessen",
    "Don’t have an account yet?":"Sie haben keinen Account?",
    "Sign Up":"Anmelden",
    "Sign Up to get free reward":"Sign Up to get free reward",
    "First name":"Vorname",
    "Last name":"Nachname",
    "Email": "E-Mail",
    "Confirm Password":"Password bestätigen"

};
